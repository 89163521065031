<template>
  <div>
    <landingCover
        :coverImage="coverImage"
        :title="$t('permissions.headerTitle')"
        :subtitle="$t('permissions.headerSubtitle')"
        routerName=""
        :btnTitle="$t('permissions.extractNow')"
        :otherLogo= false
        routerAboutName=""
    />
    <landing-details
        :img="landingDetailsImage"
        :details="$t('permissions.headerDetails')"
    />
    <landing-features :features="features" />
    <landing-how :items="items" />
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import landingCover from '../../mainComponentsLandingPage/landingCover.vue'
import landingDetails from '../../mainComponentsLandingPage/landingDetails.vue'
import landingFeatures from '../../mainComponentsLandingPage/landingFeatures.vue'
import landingHow from '../../mainComponentsLandingPage/landingHow.vue'

export default {
  name: 'permissions',
  components: {
    landingCover,
    landingDetails,
    landingFeatures,
    landingHow
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      coverImage: require('@/assets/images/ibbil/insurance/insurance-cover.jpeg'),
      landingDetailsImage: require('@/assets/images/ibbil/insurance/insurance-cover.jpeg')
      // features: [
      //   {
      //     icon: 'img',
      //     title: 'المستهدفين من التصاريح',
      //     desc: 'جميع ملاك الإبل بمختلف أنواعها داخل المملكة العربية السعودية حيث تستطيع الحصول على تصاريح داخل' +
      //         'المملكة و ذلك من خلال اتباع الخطوات الموجودة في الموقع إبل'
      //   },
      //   {
      //     icon: 'img',
      //     title: 'تصاريح امتلاك الإبل',
      //     desc: 'الآن يمكنك الحصول على تصاريح إمتلاك الإبل و مشاركتها في المسابقات من خلال منصة إبل بكل سهولة'
      //   }
      // ],
      // items: [
      //   {
      //     title: 'كيفية إستخراج التصاريح ؟',
      //     desc: 'اذا قررت إستخراج التصاريح داخل المملكة العربية السعودية، إذا عليك الإشتراك من خلال أيقونة إستخرج\n' +
      //         '                   الآن، و من ثم قم بتعبئة النموذج مع مراعاة تعبئة جميع الطلبات في الحقول، و بذلك تكون قد قدمت طلبك\n' +
      //         '     لإستخراج تصاريح موثقة من وزارة الزراعة',
      //     hasBtn: true,
      //     reverse: false,
      //     text: 'أستخرج الأن'
      //   },
      //   {
      //     title: 'إستخراج تصاريح إبل',
      //     desc: 'بعد الإنتهاء من تعبئة النموذج، سيظهر لك سعر التصريح، و في حالة قبولك للسعر سيتم تحويلك لإحدى\n' +
      //         '               أنظمة الدفع من خلال إحدى وسائل الدفع المعتمدة في المملكة العربية السعودية و بعدها تصدر لك\n' +
      //         '       التصاريح و يمكنك إستخدامها بكل سهولة بعد ذلك',
      //     hasBtn: false,
      //     reverse: true
      //   }
      // ]
    }
  },
  computed: {
    features () {
      return [
        {
          icon: 'img',
          title: this.$t('permissions.whoObtainPermits'),
          desc: this.$t('permissions.whoObtainPermitsDesc')
        },
        {
          icon: 'img',
          title: this.$t('permissions.camelOwnershipPermits'),
          desc: this.$t('permissions.camelOwnershipPermitsDesc')
        }
      ]
    },
    items () {
      return [
        {
          title: this.$t('permissions.howObtainPermits'),
          desc: this.$t('permissions.howObtainPermitsDesc'),
          hasBtn: true,
          reverse: false,
          text: this.$t('permissions.extractNow')
        },
        {
          title: this.$t('permissions.issuingCamelPermits'),
          desc: this.$t('permissions.issuingCamelPermitsDesc'),
          hasBtn: false,
          reverse: true
        }
      ]
    }
  }
}
</script>
